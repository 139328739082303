import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, SvgIcon, Typography, Popper, Paper, Divider, Link, Slide, Drawer } from "@mui/material";
// import { useWeb3Context } from "src/hooks/web3Context";
import { t, Trans } from "@lingui/macro";
import { ConnectButton as RainbowConnectButton } from "@rainbow-me/rainbowkit";
import { useMobile } from "../../hooks";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as DexscreenerIcon } from "src/assets/icons/dexscreener.svg";
import { ReactComponent as PancakeSwapIcon } from "src/assets/icons/pancake_swap.svg";
import { ReactComponent as UniswapIcon } from "src/assets/icons/uniswap.svg";
import TokenIcon from "src/assets/icons/ohm.svg";
import USDTIcon from "src/assets/tokens/USDT.svg";
import { useAppSelector } from "src/hooks";
import { shorten, trim } from "src/helpers";
import { addresses, TOKEN_DECIMALS } from "src/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import { useChainId, useAccount, useDisconnect } from "wagmi";
import { useDispatch } from "react-redux";
import { success, error } from "src/slices/MessagesSlice";

const CustomRightDrawer = styled(props => <Drawer {...props} />)(({ theme }) => ({
  "& .MuiDrawer-paperAnchorRight": {
    // border: "2px solid #434952",
    // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
    background: "linear-gradient(to bottom , rgb(67, 73, 81), rgb(33, 36, 40))",
    boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
  },
}));

function ConnectMenu({ theme }) {
  const { isSmallScreen } = useMobile();
  const chainID = useChainId();
  const dispatch = useDispatch();
  const [isWaringWrongNetwork, setIsWaringWrongNetwork] = useState(false);
  // console.log("chain", chainID);
  // const { connect, disconnect, connected, web3, chainID, address } = useWeb3Context();
  const networkID = chainID;
  const { isConnected: connected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const [anchorEl, setAnchorEl] = useState(null);

  const [isHovering, setIsHovering] = useState(false);
  const isAppLoading = useAppSelector(state => state.app.loading);
  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;
  const THA_ADDRESS = addresses[networkID].THA_ADDRESS;
  const TUSDB_ADDRESS = addresses[networkID].TUSDB_ADDRESS;
  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });
  useEffect(() => {}, []);
  // const pendingTransactions = useSelector(state => {
  //   return state.pendingTransactions;
  // });
  const ohmBalance = useAppSelector(state => {
    return state.account.balances.ohm;
  });

  const sohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sOHM;
  });
  const usdtBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.usdt;
  });
  const thaBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.tha;
  });
  // const thaExchangeRate = useAppSelector(state => {
  //   return state.account.thaExchangeRate;
  // });
  // console.log("thaExchangeRate", thaExchangeRate);
  // let buttonText = <Trans>Connect Wallet</Trans>;
  // let clickFunc = connect;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const addTokenToWallet = async (tokenSymbol, tokenAddress) => {
    // console.log("addTokenToWallet", tokenSymbol, tokenAddress);
    if (window.ethereum) {
      const host = window.location.origin;
      let tokenPath;
      let tokenDecimals = TOKEN_DECIMALS;
      switch (tokenSymbol) {
        case "LGNS":
          tokenPath = TokenIcon;
          break;
        case "sLGNS":
          tokenPath = TokenIcon;
          break;
        case "THA":
          tokenPath = TokenIcon;
          break;
        case "A":
          tokenPath = TokenIcon;
          tokenDecimals = "18";
          break;
        default:
          tokenPath = TokenIcon;
      }
      const imageURL = `${host}/${tokenPath}`;
      // console.log("imageURL:", imageURL);
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: imageURL,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const open = Boolean(anchorEl);

  const copyLink = () => {
    // console.log("123copy");
    dispatch(success(t`Copy successful!`));
    // setInvitationOpen(false);
    setAnchorEl(null);
  };

  const handleDisconnect = () => {
    disconnect();
    setAnchorEl(null);
  };

  const goPancakeSwap = () => {
    window.open(
      `https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
      "_blank",
    );
    // window.open(`https://quickswap.exchange/#/swap`, "_blank");
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
    //     addresses[chainID].USDT_ADDRESS
    //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
  };

  const handleOpenDexscreener = () => {
    window.open("https://dexscreener.com/polygon/0x882df4b0fb50a229c3b4124eb18c759911485bfb", "_blank");
  };
  return (
    <Box>
      <RainbowConnectButton.Custom>
        {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
              // className={styles.connectButton}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Box
                      component={"div"}
                      sx={{
                        borderRadius: "30px",
                        height: "44px",
                        border: "1.3px solid rgb(193, 60, 58)",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                        "&:hover": {
                          background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          height: "100%",
                          background: "transparent",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={openConnectModal}
                        variant="contained"
                        // onMouseOver={() => setIsHovering(true)}
                        // onMouseLeave={() => setIsHovering(false)}
                      >
                        <Typography
                          sx={{
                            color: "#EBEBEB",
                            textShadow: "1px 0px  0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Connect wallet</Trans>
                        </Typography>
                      </Button>
                    </Box>
                  );
                }

                if (chain.unsupported) {
                  if (!isWaringWrongNetwork) {
                    dispatch(error(t`Wrong network, please switch to Polygon mainnet 137`));
                    setIsWaringWrongNetwork(true);
                  }
                  return (
                    <Box
                      component={"div"}
                      sx={{
                        borderRadius: "30px",
                        height: "42px",
                        border: "1.3px solid rgb(193, 60, 58)",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                        "&:hover": {
                          background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          height: "100%",
                          background: "transparent",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={openChainModal}
                        variant="contained"
                        onMouseOver={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                      >
                        <Typography
                          sx={{
                            color: "#EBEBEB",
                            textShadow: "1px 0px  0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Wrong network</Trans>
                        </Typography>
                      </Button>
                    </Box>
                  );
                }

                return (
                  <Box
                    component={"div"}
                    sx={{
                      height: "42px",
                      borderRadius: "30px",
                      border: "1.3px solid rgb(193, 60, 58)",
                      background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                      boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                      "&:hover": {
                        background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                      },
                    }}
                    // onMouseEnter={e => handleClick(e)}
                    // onMouseLeave={e => handleClick(e)}
                    onClick={e => handleClick(e)}
                  >
                    <Button
                      sx={{
                        height: "100%",
                        background: "transparent",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      // onClick={openAccountModal}
                      variant="contained"
                      // onMouseOver={() => setIsHovering(true)}
                      // onMouseLeave={() => setIsHovering(false)}
                    >
                      <Typography
                        sx={{
                          color: "#EBEBEB",
                          textShadow: "1px 0px 0px #000",
                          fontFamily: "Neue Haas Grotesk Text Pro",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        {account?.displayName||''}
                      </Typography>
                    </Button>
                  </Box>
                );
              })()}
            </div>
          );
        }}
      </RainbowConnectButton.Custom>
      <CustomRightDrawer anchor={"right"} open={open} onClose={handleClick} className="right-drawer">
        <Box
          sx={{
            px: 1.875,
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          }}
        >
          <Box className="right-drawer-close" display="flex" justifyContent="flex-end" alignItems="center">
            <Box className="close-box" onClick={handleClick}>
              <SvgIcon component={XIcon} />
            </Box>
          </Box>
          <Box className="token-box-container">
            <Box
              onClick={handleOpenDexscreener}
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                py: 1.75,
                px: 2.5,
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <SvgIcon
                  color="primary"
                  component={DexscreenerIcon}
                  style={{ width: "28px", height: "34px", marginRight: "15px" }}
                  viewBox="0 0 28 34"
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    <Trans>Get on</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    <Trans>Dexscreener</Trans>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box style={{ marginLeft: "20px" }}>
                  <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
                </Box>
              </Box>
            </Box>
            <Box
              onClick={goPancakeSwap}
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 5,
                py: 1.75,
                px: 2.5,
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <SvgIcon
                  color="primary"
                  component={UniswapIcon}
                  style={{ width: "32px", height: "36px", marginRight: "15px" }}
                  viewBox="0 0 32 36"
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    <Trans>Get on</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    <Trans>Uniswap</Trans>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box style={{ marginLeft: "20px" }}>
                  <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                py: 1.75,
                px: 2.5,
              }}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
                <img src={TokenIcon} alt="LGNS" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  LGNS
                </Typography>
              </Box>
              <Box display="flex">
                <Box>
                  <Typography
                    align="right"
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      mb: 0.5,
                    }}
                  >
                    {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(ohmBalance), 4)}</>}
                  </Typography>
                  <Typography
                    align="right"
                    sx={{
                      color: "#676b74",
                      fontFamily: "Euclid Square",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {isAppLoading ? (
                      <Skeleton width="80px" />
                    ) : (
                      <>{ohmBalance > 0 ? trim(Number(ohmBalance * marketPrice), 4) : "0"}</>
                    )}
                  </Typography>
                </Box>
                {/* <Box style={{ marginLeft: "20px" }}>
                  <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
                </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                py: 1.75,
                px: 2.5,
              }}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
                <img src={USDTIcon} alt="USDT" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  USDT
                </Typography>
              </Box>
              <Box display="flex">
                <Box>
                  <Typography
                    align="right"
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "Euclid Square",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      mb: 0.5,
                    }}
                  >
                    {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(usdtBalance), 4)}</>}
                  </Typography>
                  <Typography
                    align="right"
                    sx={{
                      color: "#676b74",
                      fontFamily: "Euclid Square",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {isAppLoading ? (
                      <Skeleton width="80px" />
                    ) : (
                      <>{usdtBalance > 0 ? trim(Number(usdtBalance), 4) : "0"}</>
                    )}
                  </Typography>
                </Box>
                {/* <Box style={{ marginLeft: "20px" }}>
                  <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
                </Box> */}
              </Box>
            </Box>
          </Box>
          {/* add */}
          {/* <Box className="add-token-container">
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                px: 2.5,
                py: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => addTokenToWallet("LGNS", OHM_ADDRESS)}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TokenIcon} alt="THA" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  LGNS
                </Typography>
              </Box>
              <Button type="text">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                px: 2.5,
                py: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => addTokenToWallet("sLGNS", SOHM_ADDRESS)}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TokenIcon} alt="THA" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  sLGNS
                </Typography>
              </Box>
              <Button type="text">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 2,
                px: 2.5,
                py: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => addTokenToWallet("THA", THA_ADDRESS)}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TokenIcon} alt="THA" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  THA
                </Typography>
              </Box>
              <Button type="text">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                cursor: "pointer",
                mb: 5,
                px: 2.5,
                py: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => addTokenToWallet("A", TUSDB_ADDRESS)}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={TokenIcon} alt="A" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  A
                </Typography>
              </Box>
              <Button type="text">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Euclid Square",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </Button>
            </Box>
          </Box> */}

          {/* invite */}
          <Box className="invite-container">
            <Box display="flex" alignItems="center" justifyContent="space-between" className="invite-title">
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Euclid Square",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                <Trans>Invitation link:</Trans>
              </Typography>
              <CopyToClipboard text={`${window.location.origin}/invite?code=${address}`}>
                <Box style={{ cursor: "pointer" }} onClick={copyLink}>
                  <SvgIcon component={CopyIcon} viewBox="0 0 18 18" />
                </Box>
              </CopyToClipboard>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #5F6674",
                background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
                px: 2,
                py: 1.875,
              }}
            >
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Euclid Square",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >{`${window.location.origin}/invite?code=${address}`}</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              pb: 10,
            }}
          >
            <Button type="text" onClick={handleDisconnect}>
              <Typography
                sx={{
                  color: "#FF4D4D",
                  fontFamily: "Euclid Square",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                <Trans>Disconnect</Trans>
              </Typography>
            </Button>
          </Box>
        </Box>
      </CustomRightDrawer>
    </Box>
  );
}

export default ConnectMenu;
