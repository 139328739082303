import React, { useState, useEffect } from "react";
import "./Community.scss";
import {
    Box,
    Grid,
    Paper,
    Typography,
    Zoom,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    CircularProgress,
    Skeleton,
    Modal,
    SvgIcon,
    IconButton
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import NoData from "src/assets/images/noData.svg";
import { useAppSelector } from "src/hooks";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { communityTurbine } from "src/slices/CommunitySlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";
import { trim, shorten } from "src/helpers";
import { ethers } from "ethers";
import dayjs from "dayjs";
import axios from "axios";
import { PullToRefresh } from "src/components/PullToRefresh";


import V0 from '../../assets/level/V0.png';
import V1 from '../../assets/level/V1.png';
import V2 from '../../assets/level/V2.png';
import V3 from '../../assets/level/V3.png';
import V4 from '../../assets/level/V4.png';
import V5 from '../../assets/level/V5.png';
import V6 from '../../assets/level/V6.png';
import { useAuthContext } from "src/auth/use-auth";
import { SignForm } from "../auth/SignForm";


const levelImages: any = {
    0: V0,
    1: V1,
    2: V2,
    3: V3,
    4: V4,
    5: V5,
    6: V6
}

const CommunitySubNodes = ({ address, isSmallScreen }: any) => {
    const [loading, setLoading] = useState(false);
    const [subNodes, setSubNodes] = useState([]);
    const { login, user }: any = useAuthContext();
    useEffect(() => {
        console.log('[debug]subnodes:',address,user);
        if (!address) return;
        setLoading(true)

        axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/community/${address}/subnodes`).then(ret => {
            setSubNodes(ret.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [address,user])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: isSmallScreen ? '45px' : '145px' }}>
            {
                subNodes?.sort((p1: any, p2: any) => {
                    if (p1?.level > p2?.level) return -1;
                    else if (p1?.level < p2?.level) return 1;
                    else return 0;
                }).map((n: any, index: any) => {
                    return <Box key={'snk_' + index}>
                        <img src={levelImages[n?.level]} style={{ height: 8 }} />
                    </Box>
                })
            }
        </Box>
    )
}

const MyCommunityData = ({ isSmallScreen, isLoading, rankingListData, getLevelStr, onClickRecord }: any) => {
    return (
        <Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: 5,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: isSmallScreen ? "12px" : "24px",
                        background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                    }}
                >
                    <Box display="flex" alignItems="center" sx={{ px: isSmallScreen ? 2.5 : 3.75, height: "66px" }}>
                        <Typography
                            sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "21px",
                                fontStyle: "normal",
                                fontWeight: "700",
                            }}
                        >
                            <Trans>My Reward</Trans>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            height: "1px",
                            background: "#0B0C0A",
                            boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                        }}
                    />
                    <Box>
                        <TableContainer sx={{ minHeight: "330px" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "12px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                                pl: isSmallScreen ? 5.25 : 7,
                                            }}
                                        >
                                            <Trans>Block</Trans>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "12px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                            }}
                                        >
                                            <Trans>Reward</Trans>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "12px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                            }}
                                        >
                                            <Trans>Level</Trans>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {<TableBody>
                                    {isLoading ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                sx={{
                                                    height: "300px",
                                                    borderBottom: "none",
                                                }}
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "100%" }}
                                                    display="flex"
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : rankingListData && rankingListData.length > 0 ? (
                                        rankingListData.map((item: any, index: number) => (
                                            <TableRow sx={{ height: "56px" }} key={index}>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25 }}>
                                                    <Box display="flex" alignItems={"center"}>
                                                        <a href={"https://polygonscan.com/block/" + item.blockNumber}>
                                                            <Box sx={{ height: "24px" }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: "rgba(0, 112, 215, 1)",
                                                                        // color: "#C7C8CC",
                                                                        fontFamily: "AGaramond LT",
                                                                        fontSize: isSmallScreen ? "16px" : "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "400",
                                                                        textDecoration: 'underline'
                                                                    }}
                                                                >
                                                                    <Trans>{item.blockNumber}</Trans>
                                                                </Typography>
                                                            </Box>
                                                        </a>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25, width: "40%" }} align="center" onClick={() => onClickRecord(item)}>
                                                    <Typography
                                                        sx={{
                                                            color: "#C7C8CC",
                                                            fontFamily: "AGaramond LT",
                                                            fontSize: isSmallScreen ? "16px" : "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            border: '1px solid #ed0000',
                                                            borderRadius: '15px'
                                                        }}
                                                    >
                                                        {trim(item.reward, 4)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="right">
                                                    <Typography
                                                        sx={{
                                                            color: "#C7C8CC",
                                                            fontFamily: "AGaramond LT",
                                                            fontSize: isSmallScreen ? "16px" : "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {/* {dayjs(item.createdAt).format("YYYY/MM/DD ")} */}
                                                        {getLevelStr(item.level || 0)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                sx={{
                                                    height: "300px",
                                                    borderBottom: "none",
                                                }}
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "100%" }}
                                                    display="flex"
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                >
                                                    <img src={NoData} alt="No Data" />
                                                    <Typography
                                                        sx={{
                                                            pt: 2,
                                                            color: "#C7C8CC",
                                                            fontFamily: "AGaramond LT",
                                                            fontSize: "14px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        <Trans>No data</Trans>
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Paper>
        </Zoom>
    )
}

const MyCommunityNodes = ({ data, isSmallScreen }: any) => {
    const marketPrice = useAppSelector(state => {
        return state.app.marketPrice;
    });
    return (
        <Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: 5,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        pb: 3,
                        borderRadius: isSmallScreen ? "12px" : "24px",
                        background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                    }}
                >

                    <Box sx={{ color: '#cdd' }}>
                        <Box display="flex" alignItems="center" sx={{ px: isSmallScreen ? 2.5 : 3.75, height: "66px" }}>
                            <Typography
                                sx={{
                                    color: "#C7C8CC",
                                    fontFamily: "AGaramond LT",
                                    fontSize: "21px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                }}
                            >
                                <Trans>My Nodes</Trans>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: "1px",
                                background: "#0B0C0A",
                                boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                        />

                        <Table sx={{ color: '#cdd' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{
                                        borderBottom: "none", color: "#868B8F",
                                        fontFamily: "Roboto",
                                        fontSize: isSmallScreen ? "12px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}>
                                        <Typography>
                                            <Trans>Address</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{
                                        borderBottom: "none", color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "12px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}>
                                        <Typography>
                                            <Trans>Stake</Trans>
                                        </Typography>
                                        <Typography>
                                            (<Trans>Rebase</Trans>)
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{
                                        borderBottom: "none", color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "12px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}>
                                        <Typography>
                                            <Trans>Nodes</Trans>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((d: any, index: any) => {
                                        return (
                                            <TableRow key={'kkk_' + index}>
                                                <TableCell align="center" sx={{ borderBottom: "none" }}>
                                                    <Typography sx={{ color: '#ccc' }}>
                                                        {
                                                            shorten(d.path)
                                                        }
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="right" sx={{ color: '#ccc', borderBottom: 'none' }}>
                                                    <Typography>
                                                        {
                                                            Number(d.stake).toFixed(4)
                                                        } <strong style={{ fontSize: '10px' }}>LGNS</strong>
                                                    </Typography>
                                                    <Typography>
                                                        ${
                                                            Number(Number(marketPrice || 0) * Number(d.stake)).toFixed(2)
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none" }}>
                                                    <CommunitySubNodes address={d.path} isSmallScreen={isSmallScreen} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>

                </Box>
            </Paper>
        </Zoom>


    )

}

const Community = () => {
    const { isSmallScreen } = useMobile();
    const top3Array = ["#FFC700", "#fff", "#ED7B13"];
    const dispatch = useDispatch();
    const { provider, address, connected, connect, chainID } = useWeb3Context();
    const [activeTab, setActiveTab] = useState(0);
    const [nodes, setNodes] = useState([])
    const [showDetail, setShowDetail] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [identityLevel, setIdentityLevel] = useState(0);
    const [rankingListData, setRankingListData] = useState<any>({});
    const communityReward = useAppSelector(state => state.account.reward.community);
    const stakingReward = useAppSelector(state => state.account.reward.staking);
    // const additionStakingReward = useAppSelector(state => state.account.reward.additionStaking);
    const totalReward = useAppSelector(state => state.account.reward.total);
    const identity = useAppSelector(state => state.community.identity);
    const { login, user }: any = useAuthContext();
    // const i18nIdentities = {
    //   Master: t`Master`,
    //   Disciple: t`Messenger`,
    //   Normal: t`Normal`,
    // };

    const i18nIdentities = {
        '6': t`Master`,
        '1': t`Missionary`,
        '2': t`Samurai`,
        '4': t`Messenger`,
        '3': t`Knight`,
        '5': t`Venerable`,
        '0': t`Normal`,
    };
    const i18nRewardTypes: any = {
        'Level Reward': t`Level Reward`,
        'Level Discrepancy': t`Level Discrepancy`,
        'Exceeding Reward': t`Exceeding Reward`,
        'Peer Reward': t`Peer Reward`
    }

    const handleCloseDetail = () => { setShowDetail(false) };
    const getLevelStr = (level: any) => {
        return (i18nIdentities as any)[identityLevel];
    }
    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleTurbine = (type: string) => {
        dispatch(communityTurbine({ type, provider, networkID: chainID, address }));
    };

    const renderReward = (rewards: any) => {
        if (!rewards) return null;

        const tmps = rewards.map((r: any) => {
            if (r.reward && !r.value) {
                r.value = r.reward;
            }
            return r;
        })

        const total = tmps.reduce((s: any, p: any) => {
            return s + p.value * 1;
        }, 0);

        return tmps?.map((p: any, index: any) => {
            return (
                <TableRow key={'idk_' + index}>
                    <TableCell sx={{ color: '#fff' }}>
                        <Typography>
                            {i18nRewardTypes[p.type]}
                        </Typography>

                    </TableCell>
                    <TableCell>
                        {p.type != 'Level Reward' ? <Typography sx={{ color: '#fff' }}>
                            {shorten(p.source)}
                        </Typography> : null}
                        <Typography sx={{ color: '#00ed00' }}>
                            {Number(p.base).toFixed(2)}
                        </Typography>

                    </TableCell>
                    <TableCell sx={{ color: '#fff' }}>
                        {Number(p.value * 100 / total).toFixed(2)}%
                    </TableCell>
                </TableRow>
            )
        })
    }

    const load1 = async () => {
        if (!address||!user||(user?.address??'').toLowerCase() != address.toLowerCase()) return;
        await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/community/${address}/nodes`).then(ret => {
            setNodes(ret.data);
        });

    }
    useEffect(() => {
        load1();
    }, [address,user])

    const load2 = async () => {
        if (!address||!user|| (user?.address??'').toLowerCase() != address.toLowerCase()) return;
        try {
            setIsLoading(true);
            // console.log("fetch rewardList", process.env.REACT_APP_PUBLIC_API_URL);
            //fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/comm/rewardList`, {
            await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/community/${address}`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
            })
                .then(response => response.json())
                .then(data => {
                    setIdentityLevel(data.level || 0);
                });

            await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/community/${address}/rewards`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
            })
                .then(response => response.json())
                .then(data => {
                    // console.log(data, "fetch rewardList");
                    // const array = data.map((item: any) => {
                    //   return { ...item, reward: ethers.utils.formatUnits(String(item.reward), "9") };
                    // });
                    // console.log(array, "array");
                    setRankingListData(data);
                    setIsLoading(false);
                });

        } catch (error) {
            setIsLoading(false);
            setRankingListData([]);

            console.log("err", error);
        }
    }
    useEffect(() => {
        load2();
    }, [address,user]);

    // console.log('[debug]user:', user, address,(user?.address || '').toLowerCase() != address?.toLowerCase());
    
    if (!address) {
        return <Box>
        </Box>
    }
    else if (!user || (user.address || '').toLowerCase() != address.toLowerCase()) {
        return <SignForm/>
    }
  

    return (
        <PullToRefresh onRefresh={async () => {
            if (!address) return;
            await load1();
            await load2();
        }}>
            <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                sx={{
                    pt: isSmallScreen ? 2.5 : 0,
                    px: isSmallScreen ? 1.875 : 0,
                }}
            >
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 3.75 : 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                        >
                            <Box
                                display={"flex"}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                sx={{ pt: isSmallScreen ? 5 : 5.75, pb: isSmallScreen ? 5 : 3.75, px: isSmallScreen ? 2.5 : 0 }}
                            >
                                <Typography
                                    sx={{
                                        color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "18px" : "26px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                        mb: isSmallScreen ? 0.625 : 1,
                                    }}
                                >
                                    <Trans>Community Reward(LGNS)</Trans>
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "40px" : "60px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "normal",
                                        wordBreak: "break-all",
                                    }}
                                    align="center"
                                >
                                    {trim(totalReward, 4)}
                                </Typography>
                                <LoadingButton
                                    variant="contained"
                                    disabled={
                                        !connected || Number(communityReward) == 0 || isPendingTxn(pendingTransactions, "community_turbine")
                                    }
                                    loading={isPendingTxn(pendingTransactions, "community_turbine")}
                                    // endIcon={isPendingTxn(pendingTransactions, "community_turbine") && <CircularProgress size={16} />}
                                    // loadingPosition="end"
                                    onClick={() => handleTurbine("community")}
                                    sx={{
                                        borderRadius: "30px",
                                        width: "100%",
                                        maxWidth: isSmallScreen ? "143px" : "259px",
                                        px: isSmallScreen ? 0 : 2,
                                        border: "1.3px solid #B50C05",
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        mb: "30px",
                                        height: "40px",
                                        "&:hover": {
                                            background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {txnButtonText(pendingTransactions, "community_turbine", t`Turbine`)}
                                    </Typography>
                                </LoadingButton>
                                {identity !== "" || !connected ? (
                                    <Box
                                        display="flex"
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#868B8F",
                                                fontSize: "20px",
                                                fontWeight: "400",
                                                mr: 1,
                                            }}
                                        >
                                            <Trans>My identity:</Trans>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: identity === "Master" ? "#9068FF" : identity === "Disciple" ? "#FF7751" : "#C7C8CC",
                                                fontSize: "20px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {/* {i18nIdentities[identity as keyof typeof i18nIdentities]} */}
                                            {(i18nIdentities as any)[identityLevel]}
                                        </Typography>
                                        <img src={levelImages[identityLevel || 0]} style={{ marginLeft: 1, marginTop: 3, height: 18 }} />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            maxWidth: "300px",
                                            margin: "0 auto",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Skeleton variant="text" width="100%" height="30px" />
                                    </Box>
                                )}
                            </Box>

                        </Box>
                    </Paper>
                </Zoom>

                <MyCommunityNodes isSmallScreen={isSmallScreen} data={nodes}></MyCommunityNodes>

                <MyCommunityData isSmallScreen={isSmallScreen} isLoading={isLoading} rankingListData={rankingListData}
                    onClickRecord={(r: any) => {
                        setShowDetail(true);
                        setCurrentRecord(r);
                    }}
                    getLevelStr={getLevelStr} />
                <Modal open={showDetail} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClose={handleCloseDetail}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            width: isSmallScreen ? "100%" : "auto",
                            maxWidth: isSmallScreen ? "100%" : "512px",
                            maxHeight: "426px",
                            overflow: 'scroll'
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                maxHeight: "426px",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                px: isSmallScreen ? 2.125 : 3.75,
                                py: 2.5,
                            }}
                        >
                            <Box display="flex" justifyContent={"space-between"} alignItems="center">
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "14px" : "20px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        // width: "86%",
                                    }}
                                    align="center"
                                // variant="h3"
                                >
                                    <Trans>Reward parts</Trans>
                                </Typography>
                                <IconButton
                                    onClick={handleCloseDetail}
                                    sx={{
                                        p: 0,
                                    }}
                                >
                                    <SvgIcon sx={{ color: "#C7C8CC" }} component={XIcon} />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{
                                    pt: isSmallScreen ? 0 : 1.875,
                                    maxHeight: "386px",
                                    overflow: 'scroll'
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography sx={{ color: '#C7C8CC' }}>
                                                    <Trans>Type</Trans>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#C7C8CC' }}>
                                                    <Trans>Address</Trans>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#C7C8CC' }}>
                                                    <Trans>Percent</Trans>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            renderReward((currentRecord as any)?.rewardPath)

                                        }

                                    </TableBody>
                                </Table>


                            </Box>
                        </Box>
                    </Paper>
                </Modal>
            </Box>
        </PullToRefresh>
    );
};

export default Community;
