import { AppBar, Toolbar, Box, Button, SvgIcon, Link, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
// import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import LocaleSwitcher from "./LocaleSwitch.jsx";
import ConnectMenu from "./ConnectMenu.jsx";
import { Trans } from "@lingui/macro";
import { useIsPath } from "../../hooks";
import { addresses } from "src/constants";
import "./topbar.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import MenuDrop from "./MenuDrop";
const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: "10px",
      paddingBottom: "60px",
    },
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    backgroundColor: "transparent!important",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("981")]: {
      display: "none",
    },
  },
}));

function TopBar({ handleDrawerToggle }) {
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();
  const { chainID } = useWeb3Context();
  const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const buttonStyles = isHovering ? " hovered-button" : "";
  const goPancakeSwap = () => {
    // window.open(
    //   `https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    // router.push("/swap");
  };

  const handleGoOfficalPage = () => {
    window.open("https://origindao.io/", "_blank");
  };

  return (
    <AppBar position={isSmallScreen ? "fixed" : "sticky"} className={`${classes.appBar}`} elevation={0}>
      <Toolbar disableGutters className={`dapp-topbar`}>
        <Box
          display="flex"
          sx={{ mx: isSmallerScreen ? 0 : 2, mt: isSmallerScreen ? 0 : 3.5, px: isSmallerScreen ? 1.25 : 4.5 }}
          className={`barContainer`}
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
         
          {isSmallerScreen ? (
            <>
              <Box onClick={handleDrawerToggle}>
                <MenuIcon />
              </Box>
              <Box display="flex">
                <LocaleSwitcher />
                <ConnectMenu />
              </Box>
            </>
          ) : (
            <>
              <Box
                onClick={handleGoOfficalPage}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Logo />
              </Box>
              <Box display="flex" alignItems={"center"}>
                <MenuDrop />
                {/* <Box sx={{ px: 1.675, py: 1 }}>
                  <Link href="https://lend.originworld.org/" target="_blank" rel="noopener">
                    <Typography
                      sx={{
                        color: "#c7c8cc",
                        textShadow: "0px 1px 0px #000",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      <Trans>Lending</Trans>
                    </Typography>
                  </Link>
                </Box>
                <Box sx={{ px: 1.675, py: 1 }}>
                  <Link href="https://swap.originworld.org/#/" target="_blank" rel="noopener">
                    <Typography
                      sx={{
                        color: "#c7c8cc",
                        textShadow: "0px 1px 0px #000",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      <Trans>Bridge</Trans>
                    </Typography>
                  </Link>
                </Box>
                <Box sx={{ px: 1.675, py: 1 }}>
                  <Link href="" target="_blank" rel="noopener">
                    <Typography
                      sx={{
                        color: "#c7c8cc",
                        textShadow: "0px 1px 0px #000",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      <Trans>Dex</Trans>
                    </Typography>
                  </Link>
                </Box> */}
                <Link
                  sx={{
                    px: 1,
                    py: 0.25,
                    borderRadius: "14px",
                    border: "1px solid #E93F25",
                    height: "20px",
                    cursor: "pointer",
                    mr: 1,
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/swap"
                >
                  <Typography
                    sx={{
                      background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Buy</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 1,
                    py: 0.25,
                    borderRadius: "14px",
                    border: "1px solid #E93F25",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="https://v1.origindefi.io"
                  target="_blank"
                >
                  <Typography
                    sx={{
                      background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>V1</Trans>
                  </Typography>
                </Link>
                {/* <Box
              sx={{
                borderRadius: "50%",
                border: "2.4px solid rgba(28, 30, 33, 0.60)",
                background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
              }}
            ></Box> */}
                <LocaleSwitcher />
                <ConnectMenu />
              </Box>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
