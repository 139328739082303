import { t, Trans } from "@lingui/macro";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TextField,
    Skeleton,
    Grid,
    Modal,
    Checkbox,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { trim, prettyVestingPeriod } from "src/helpers";

import NoData from "src/assets/images/noData.svg";
import {
    getTurbineData,
    getSilenceAmount,
    approveTurbine,
    turbineSilence,
    turbineRedeem,
    syncSilenceAmount,
} from "src/slices/TurbineSlice";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { getBalances } from "src/slices/AccountSlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";

import { useAccount } from "wagmi";
import { error } from "../../slices/MessagesSlice";
import useDebounce from "../../hooks/Debounce";

import { PullToRefresh } from "src/components/PullToRefresh";
import { WebKeyBanner } from "./WebKeyBanner";
import { getStakeExInfo, getStakeExData, exStake, exStakeApprove, claimNFTShard, getOwnedNFT, getOwnedNFTShard, forgeNFT, approveArtisan, exStakeRedeem } from 'src/slices/StakeExSlice';
import WebKeyCaims from "./WebKeyClaims";
import nftImage from 'src/assets/images/webkey_nft.png'
import phoneImage from 'src/assets/images/webkey_phone.jpg'

import Chart from 'react-apexcharts'
import axiosInstance from "src/utils/axios";
import { SignForm } from "../auth/SignForm";
import { useAuthContext } from "src/auth/use-auth";
import { useQuery } from "react-query";

const OPEN_CLAIM = true;



export const WebKeyNFTList: React.FC<{ isSmallScreen: boolean, address: string }> = ({ isSmallScreen }) => {
    const [showSignModal, setShowSignModal] = useState(false);
    const { provider, chainID, address, connected, connect } = useWeb3Context();
    const dispatch = useDispatch();
    const { user }: any = useAuthContext();
    const { nftShards, nfts, approved } = useAppSelector(state => state.stakeex);
    const [selectedShards, setSelectedShards] = useState<any[]>([]);
    const pendingTransactions = useAppSelector(state => state.pendingTransactions);
    // console.log('[debug]nftShards',nftShards);


    const fetchStakeExList = async () => {
        return await axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL
            }/stakeex/nft`, { address }).then(ret => ret.data)
    }

    const { data: stakeExList } = useQuery(['stakeexlist', address], fetchStakeExList,
        {
            enabled: !!address,
            refetchInterval: 5000
        });

    const fetchOwnedNFT = () => {
        dispatch(getOwnedNFT({ address, provider, networkID: chainID }))
        dispatch(getOwnedNFTShard({ address, provider, networkID: chainID }))
    }

    const handleApproveNFT = async () => {
        await dispatch(approveArtisan({ address, provider, networkID: chainID, shards: selectedShards }))
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleRevokeApproveNFT = async () => {
        await dispatch(approveArtisan({ address, provider, networkID: chainID, shards: selectedShards, approve: false }))
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }
    const handleForgeNFT = () => {
        dispatch(forgeNFT({ address, provider, networkID: chainID, shards: selectedShards }))
    }


    useQuery(['ownedNFT', address], fetchOwnedNFT, {
        enabled: !!address,
        refetchInterval: 10000
    })



    const renderNFT = () => {

        return (
            <>
                {nfts.map((item: any, index: any) => {
                    return <Grid key={'nkk_' + index} item xs={6} md={4} lg={3} xl={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <img src={phoneImage} style={{ maxWidth: '120px' }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Typography color="gold">
                                #{Number(item)}
                            </Typography>
                        </Box>
                    </Grid>
                })}
                {
                    stakeExList?.filter((e: any) => !e.claimed || !e.claimed).map((item: any, index: any) => {
                        return <Grid key={'nkk_' + index} item xs={6} md={4} lg={3} xl={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <img src={nftImage} style={{ maxWidth: '120px' }} />
                            </Box>
                            {
                                OPEN_CLAIM && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button sx={{
                                        borderRadius: "20px",
                                        border: "1.3px solid #B50C05",
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                        color: "#FFFFFF",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        mb: isSmallScreen ? "20px !important" : 1,
                                        "&:hover": {
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                        },
                                    }}
                                        onClick={() => {
                                            if (!user) {
                                                setShowSignModal(true);
                                            }
                                            else handleClaim(item)
                                        }}
                                    >
                                        {!item.claimed && <Typography>
                                            {user ? <Trans>Claim</Trans> : <Trans>Sign by Wallet</Trans>}
                                        </Typography>
                                        }
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    })
                }

                {
                    nftShards.map((item: any, index: any) => {
                        const isSelected = selectedShards.includes(item);
                        return <Grid key={'nkk_' + index} item xs={6} md={4} lg={3} xl={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <img src={nftImage} style={{ maxWidth: '120px' }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', mb: 2, mt: -0.5 }}>
                                <Checkbox
                                    sx={{
                                        color: '#B50C05',
                                        '&.Mui-checked': {
                                            color: '#B50C05',
                                        },
                                    }}
                                    checked={isSelected}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            if (selectedShards.length < 10) {
                                                setSelectedShards([...selectedShards, item])
                                            }
                                        }
                                        else {
                                            setSelectedShards(selectedShards.filter((e: any) => e != item))
                                        }
                                    }}
                                />
                                <Typography color="white">
                                    #{Number(item)}
                                </Typography>
                            </Box>
                        </Grid>
                    })
                }

                {nftShards?.length > 0 && (approved ? <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <LoadingButton
                        sx={{
                            width: "100%",
                            // height: "40px",

                            borderRadius: "30px",
                            border: "1.3px solid #B50C05",
                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                            color: "#FFFFFF",
                            fontFamily: "AGaramond LT",
                            fontSize: "12px",
                            fontWeight: "700",
                            mb: isSmallScreen ? "20px !important" : 0,
                            "&:hover": {
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                            },
                        }}
                        loading={isPendingTxn(pendingTransactions, "ForgeNFT")}
                        disabled={selectedShards.length != 10}
                        variant="contained" onClick={handleForgeNFT}>
                        <Trans>Forge</Trans>
                    </LoadingButton>
                    <LoadingButton
                        sx={{
                            width: "100%",
                            // height: "40px",

                            borderRadius: "30px",
                            border: "1.3px solid #B50C05",
                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                            color: "#FFFFFF",
                            fontFamily: "AGaramond LT",
                            fontSize: "12px",
                            fontWeight: "700",
                            mb: isSmallScreen ? "20px !important" : 0,
                            "&:hover": {
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                            },
                        }}
                        loading={isPendingTxn(pendingTransactions, "ApproveNFT")}
                        variant="contained" onClick={handleRevokeApproveNFT}>

                        <Trans>Revoke Approve</Trans>

                    </LoadingButton>
                </Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <LoadingButton
                            sx={{
                                width: "100%",
                                // height: "40px",

                                borderRadius: "30px",
                                border: "1.3px solid #B50C05",
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                color: "#FFFFFF",
                                fontFamily: "AGaramond LT",
                                fontSize: "18px",
                                fontWeight: "700",
                                mb: isSmallScreen ? "20px !important" : 0,
                                "&:hover": {
                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                    boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                },
                            }}
                            loading={isPendingTxn(pendingTransactions, "ApproveNFT")}
                            variant="contained" onClick={handleApproveNFT}>

                            <Trans>Approve</Trans>

                        </LoadingButton>

                    </Box>)}

            </>
        )
    }
    const handleClaim = (item: any) => {
        // axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL}/stakeex/nft/claim`,{address:item.address,tx:item.tx}).then(ret=>{
        axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL}/stakeex/claim`, { address, tx: item.tx }).then(ret => {
            console.log(ret.data);
            const signature = ret.data.sig;
            dispatch(claimNFTShard({ address, provider, networkID: chainID, deadline: ret.data.timestamp, tx: item.tx, signature }))
        }).catch(err => {
            dispatch(error(t`Not your valid WebKey Staking`));
        })
    }
    return (
        <Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: isSmallScreen ? 1.875 : 3.125,
                }}
            >
                <Box
                    display={"flex"}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: isSmallScreen ? "12px" : "24px",
                        background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                    }}
                >
                    <Box sx={{ pt: 2, mb: 2 }}>
                        <Typography
                            sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "18px" : "26px",
                                fontStyle: "normal",
                                fontWeight: "400",
                            }}
                            align="center"
                        >
                            <Trans>WebKey NFT</Trans>
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container>
                            {
                                renderNFT()
                            }

                        </Grid>
                    </Box>
                </Box>
                <Modal open={showSignModal && !user} onClose={() => { setShowSignModal(false) }}>
                    <SignForm />
                </Modal>
            </Paper>

        </Zoom>
    )
}

const queryClient = new QueryClient();

const WebKey = () => {
    const dispatch = useDispatch();
    // const [stakeExList, setStakeExList] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const { provider, address, chainID, connected, connect } = useWeb3Context();
    const SECONDS_TO_REFRESH = 60;
    const daiBal = useAppSelector(state => state.account.balances.dai);
    const stakeExLoading = useAppSelector(state => state.stakeex.loading);

    const unitCost = useAppSelector(state => state.stakeex.unitCost);
    const available = useAppSelector(state => state.stakeex.available);
    const stock = useAppSelector(state => state.stakeex.stock);
    const period = useAppSelector(state => state.stakeex.period);
    const allowance = useAppSelector(state => state.stakeex.allowanceDAI);
    const stakeList = useAppSelector(state => state.stakeex.stakeList);


    const chartOptions = {
        chart: {
            offsetY: -16,
            sparkline: {
                enabled: true,
            },
        },
        grid: {
            padding: {
                top: 24,
                bottom: 24
            }
        },
        legend: {
            show: false
        },
        plotOptions: {
            radialBar: {
                startAngle: -110,
                endAngle: 110,
                hollow: {
                    size: '56%'
                },
                dataLabels: {
                    name: {
                        offsetY: 8,
                    },
                    value: {
                        offsetY: -40,
                        color: '#eee'
                    },
                    total: {
                        show: true,
                        label: t`Available`,
                        color: '#888'
                    }
                },
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [
                    { offset: 0, color: '#ff6760', opacity: 1 },
                    { offset: 100, color: '#b50c05', opacity: 1 },
                ],
            },
        },
        dataLabels: {
            enabled: false,
        },

    };


    const phoneCount = useMemo(() => {
        if (stakeList?.length > 0) return Math.trunc(stakeList?.length / 10);
        else return 0;

    }, [stakeList])
    // const phoneCount = 2;

    const { isSmallScreen } = useMobile();
    const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const handleStake = async () => {
        await dispatch(exStake({ address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleApprove = async () => {
        await dispatch(exStakeApprove({ address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleRedeem = async (id:any) => {
        await dispatch(exStakeRedeem({ address, provider, networkID: chainID, id }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }



    useEffect(() => {

        if (address) {
            dispatch(getStakeExData({ address, provider, networkID: chainID }));
        }
        else {
            dispatch(getStakeExInfo({ provider, networkID: chainID }))
        }
    }, [address]);



    // useEffect(() => {
    //     if (address) {
    //         axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL
    //             }/stakeex/nft`, { address }).then(ret => {
    //                 setStakeExList(ret.data);
    //             })
    //     }
    // }, [address])



    const renderPhone = () => {
        const result = [];
        for (let i = 0; i < phoneCount; i++) {
            result.push(
                <Box key={'pkk_' + i} sx={{ mb: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <img src={phoneImage} style={{ maxWidth: '300px' }} />
                </Box>
            )
        }
        return result;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <PullToRefresh onRefresh={async () => {
                if (address) {
                    await dispatch(getStakeExData({ address, provider, networkID: chainID }));
                }
                else {
                    await dispatch(getStakeExInfo({ provider, networkID: chainID }))
                }
                return new Promise((resolve) => { setTimeout(() => resolve(true), 500) })
            }}>
                <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    sx={{
                        pt: isSmallScreen ? 2.5 : 0,
                        px: isSmallScreen ? 1.875 : 0,
                    }}
                >
                    <WebKeyBanner />
                    <Zoom in={true} timeout={1000}>
                        <Paper
                            className="ohm-card linear-card"
                            sx={{
                                mb: isSmallScreen ? 1.875 : 3.125,
                            }}
                        >
                            <Box
                                display={"flex"}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: isSmallScreen ? "12px" : "24px",
                                    background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                }}
                            // sx={{ pt: 5.75, pb: 3.75 }}
                            >

                                <Box sx={{ mb: 3.75, pt: 2 }}>
                                    <Typography
                                        sx={{
                                            color: "#868B8F",
                                            fontFamily: "AGaramond LT",
                                            fontSize: isSmallScreen ? "18px" : "26px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                        align="center"
                                    >
                                        <Trans>WebKey Staking</Trans>
                                    </Typography>
                                    <Box sx={{ mt: 2, display: "flex", justifyContent: 'center' }}>
                                        {
                                            stakeExLoading ?
                                                <Skeleton variant="text" width={100}></Skeleton> :
                                                <Chart
                                                    dir="ltr"
                                                    type="radialBar"
                                                    series={[Math.trunc(available * 100 / stock)]}
                                                    options={chartOptions}
                                                    width="100%"
                                                    height={360}
                                                />
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ mb: 4 }}>
                                    {
                                        !address ? <Skeleton variant="text" width={120} height={45} /> :
                                            Number(allowance) >= (quantity * unitCost) ?
                                                <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    onClick={handleStake}
                                                    disabled={isPendingTxn(pendingTransactions, "ExStaking") || !connected}
                                                    loading={isPendingTxn(pendingTransactions, "ExStaking")}
                                                    // loadingPosition="end"
                                                    sx={{
                                                        // width: "100%",
                                                        // height: "40px",

                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                        },
                                                    }}
                                                >
                                                    <Trans>Stake</Trans>
                                                </LoadingButton> :
                                                <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    disabled={isPendingTxn(pendingTransactions, "StakeExApproving")}
                                                    loading={isPendingTxn(pendingTransactions, "StakeExApproving") || stakeExLoading}
                                                    // loadingPosition="end"
                                                    onClick={() => {
                                                        handleApprove();
                                                    }}
                                                    sx={{
                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        },
                                                    }}
                                                >
                                                    <Trans>Approve</Trans>
                                                </LoadingButton>
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "1px",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                        mb: 5.75,
                                    }}
                                />
                                <Box
                                    sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%" }}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    // alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box display="flex" sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            <Trans>Balance:</Trans>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {trim(Number(daiBal), 2)} DAI
                                        </Typography>
                                        <Box sx={{ ml: 'auto' }}>

                                            <Typography sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>Spend</Trans>: {unitCost * quantity} DAI
                                            </Typography>

                                        </Box>
                                    </Box>

                                </Box>
                            </Box>
                        </Paper>


                    </Zoom>

                    <WebKeyCaims claimList={stakeList || []} handleRedeem={handleRedeem} />

                    <WebKeyNFTList address={address} isSmallScreen={isSmallScreen} />

                </Box>
            </PullToRefresh>
        </QueryClientProvider>
    );
};

export default WebKey;
